<template>
    <div class="productionCars">
        <div class="logisticsTrainNumber">
            <el-input placeholder="请输入内容" v-model="searchVal" class="inputStyle">
                <el-button slot="append" icon="el-icon-search" @click="loadPage()"></el-button>
            </el-input>
            <el-select
                v-model="stationId"
                placeholder="请选择"
                @change="selectStation"
            >
                <el-option
                    v-for="item in stationData"
                    :key="item.station_id"
                    :label="item.station_name"
                    :value="item.station_id"
                >
                </el-option>
            </el-select>
            <ul class="taskStatistics">
                <li>
                    <h4>场站车辆：</h4>
                    <p class="scrollNumbers scrollNumbers1" id="carcount"></p>
                </li>
                <li>
                    <h4>运营车辆：</h4>
                    <p class="scrollNumbers scrollNumbers2" id="runcount"></p>
                </li>
                <li>
                    <h4>闲置车辆:</h4>
                    <p class="scrollNumbers scrollNumbers3" id="freecount"></p>
                </li>
                <li>
                    <h4>累计方量(方)：</h4>
                    <p class="scrollNumbers scrollNumbers4" id="totalvolume"></p>
                </li>
            </ul>
            <el-table
                border
                height="100%"
                :data="productionDeliveryTable"
                class="tableStyle"
            >
                <el-table-column
                    prop="shch"
                    label="车辆编号"
                >
                </el-table-column>
                <el-table-column
                    prop="pump_plate_number"
                    label="车牌号"
                >
                </el-table-column>
                <el-table-column
                    prop="project_name"
                    label="工程名称"
                >
                </el-table-column>
                <el-table-column
                    prop="biaoh"
                    label="型号"
                >
                </el-table-column>
                <el-table-column
                    prop="grand_total_complete_quantity"
                    label="运输量"
                >
                </el-table-column>
                <el-table-column
                    prop="task_number"
                    label="单号"
                >
                </el-table-column>
                <el-table-column
                    prop="pcsj"
                    label="派车时间"
                >
                </el-table-column>
                <el-table-column
                    label="状态"
                >
                    <template slot-scope="scope">
                        {{ scope.row.status | state }}
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import largeScreenFun from './JS/index.js';

const baseStation = [{ station_id: '', station_name: '全部' }];
export default {
    name: 'production-cars',
    data() {
        return {
            searchVal: '',
            time: '',
            tenantId: '',
            stationId: '',
            stationData: [],
            productionDeliveryTable: [],
            statisData: {
                runcount: 0,
                totalvolume: 0,
                carcount: 0,
                freecount: 0,
            },
        };
    },
    created() {},
    watch: {
        statisData(val, oldVal) {
            if (val.runcount !== oldVal.runcount) {
                largeScreenFun.electronicNumber('#runcount', val.runcount || 0);
            }
            if (val.volume !== oldVal.volume) {
                largeScreenFun.electronicNumber('#totalvolume', val.volume || 0);

            }
            if (val.carcount !== oldVal.carcount) {
                largeScreenFun.electronicNumber('#carcount', val.carcount || 0);
            }
            if (val.freecount !== oldVal.freecount) {
                largeScreenFun.electronicNumber('#freecount', val.freecount || 0);
            }
        },
    },
    methods: {
        async initPage() {
            this.time = this.$parent.time;
            this.stationData = baseStation.concat(this.$parent.stationData);
            this.stationId = this.stationData[0].station_id;
            // this.tenantId = this.$parent.tenantId;
            this.tenantId = 'T210510000006';
            this.initloadShipmentStatistics();
            this.loadPage();
        },
        async loadPage() {
            const data = await largeScreenFun.getProductionDeliveryCarStatis(this.time, this.stationId, this.tenantId, this.searchVal);
            this.statisData = data;
            // eslint-disable-next-line max-len
            this.productionDeliveryTable = await largeScreenFun.getProductionDeliveryTable(this.time, this.stationId, this.tenantId, this.searchVal);
        },
        // 加载发货统计数据
        async initloadShipmentStatistics() {
            // 获取车辆统计数据
            largeScreenFun.electronicNumber('#runcount', this.statisData.runcount || 0);
            largeScreenFun.electronicNumber('#totalvolume', this.statisData.volume || 0);
            largeScreenFun.electronicNumber('#carcount', this.statisData.carcount || 0);
            largeScreenFun.electronicNumber('#freecount', this.statisData.freecount || 0);
        },
        // 切换场站
        selectStation() {
            this.loadPage();
        },
    },
    filters: {
        state(val) {
            let str = '';
            switch (val) {
                case '1' :
                    str = '已入场';
                    break;
                case '2' :
                    str = '已入场称重';
                    break;
                case '21' :
                    str = '已派单';
                    break;
                case '3' :
                    str = '已接单';
                    break;
                case '31' :
                    str = '已扫码';
                    break;
                case '4' :
                    str = '已装料';
                    break;
                case '5' :
                    str = '已出场称重';
                    break;
                case '6' :
                    str = '已出场';
                    break;
                case '7' :
                    str = '已到达';
                    break;
                case '73' :
                    str = '卸料中';
                    break;
                case '77' :
                    str = '已卸料';
                    break;
                case '8' :
                    str = '已交付';
                    break;
                case '9' :
                    str = '车辆维修';
                    break;
                case '10' :
                    str = '车辆停驶';
                    break;
            }
            return str;
        },
    },
};
</script>

<style scoped>

</style>
